<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Permit in Review - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="false"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="false"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                style="padding: 10px"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-card
                    class="mx-auto"
                >
                    <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza); display: block !important;">
                        NOC
                    </v-card-title>
                    <v-card-text style="margin-top: 20px;">
                        <v-row>
                            <v-col
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <v-file-input
                                    v-model="fileNoc" 
                                    hint=" "
                                    persistent-hint
                                    accept=".*"
                                    small-chips
                                    outlined
                                    dense
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <v-row>
            <v-col
                style="padding: 10px"
                cols="12"
                lg="6"
                md="6"
                sm="12"
                v-for="(item, index) in listPermittingRequest" :key="index"
            >
                <v-card
                    class="mx-auto"
                >
                    <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza); display: block !important;">
                        <v-row :style="$vuetify.breakpoint.mobile == false ? 'height: 45px;' : ''">
                            <v-col 
                                cols="12"
                                lg="8"
                                md="12"
                                sm="12"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ item.permitTitle }}</span>
                                    </template>
                                    <span>{{ item.permitDescription }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                :style="$vuetify.breakpoint.mobile == false ? 'margin-top: -10px;' : ''"
                                cols="12"
                                lg="4"
                                md="12"
                                sm="12"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <div 
                                            v-on="on"
                                            class="div-stepper"
                                            @click="openPermitStages(item)" 
                                        >
                                            <template v-for="(itemPermitStage, indexPermitStage) in listPermitStages">

                                                <v-tooltip bottom :key="`tool-tip-stepper-${indexPermitStage}`">
                                                    <template v-slot:activator="{ on }">
                                                        <div
                                                            :key="`div-stepper-${indexPermitStage}`"
                                                            v-on="on"
                                                            class="border-rounded"
                                                            :style="getBackgroundColor(item, itemPermitStage)"
                                                        >
                                                        </div>
                                                    </template>
                                                    <span>{{ getDescriptionStagging(item, itemPermitStage) }}</span>
                                                </v-tooltip>

                                                <div
                                                    v-if="indexPermitStage < listPermitStages.length - 1"
                                                    :key="`div-stepper-divisor-${indexPermitStage}`"
                                                    class="step-divisor">
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <span>Click to view details</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                :style="$vuetify.breakpoint.mobile == false ? 'display: flex; align-content: center; align-items: center;' : ''"
                                cols="12"
                                lg="5"
                                md="12"
                                sm="12"
                            >
                                <div style="display: block;">
                                    <label style="font-size: 15px;">Responsibles:</label>
                                    <br />
                                    <label v-for="(itemResponsible, index) in item.responsibleSelected" :key="index" style="margin-left: 10px; font-weight: normal;">
                                        {{ getResponsible(item, itemResponsible) }}
                                        <br />
                                    </label>
                                </div>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="3"
                                md="12"
                                sm="12"
                            >
                                <v-switch 
                                    v-model="item.sentCity"
                                    label="Sent to the city?"
                                    color="var(--color__main)"
                                    :value=1
                                >
                                </v-switch>
                            </v-col>

                            <v-col 
                                style="margin-top: 10px;"
                                cols="12"
                                lg="4"
                                md="12"
                                sm="12"
                            >
                                <v-menu
                                    :ref="'mnuExpirationDate' + index"
                                    v-model="item.mnuExpirationDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            label="Expiration Date"
                                            v-model="item.expirationDateFormatted"
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            @blur="item.expirationDate = parseDate(item.expirationDateFormatted)"
                                            readonly
                                            outlined
                                            v-on="on"
                                            dense
                                            @change="formatExpirationDate"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="item.expirationDate"
                                        no-title
                                        @input="item.mnuExpirationDate = false"
                                        :min="today"
                                        color="primary lighten-1"
                                        @change="formatExpirationDate"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text style="margin-top: 20px;">
                        <v-row>
                            <v-col
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                            >
                                <label>Permit Number</label>
                                <v-text-field
                                    v-model="item.permitNumber"
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>

                            <v-col
                                v-if="item.permitUploadDocument == 1"
                                cols="12"
                                lg="8"
                                md="8"
                                sm="12"
                            >
                                <label>File</label>
                                <v-file-input
                                    v-model="item.fileDocument" 
                                    hint=" "
                                    persistent-hint
                                    accept=".*"
                                    small-chips
                                    outlined
                                    dense
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                ></v-file-input>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row style="margin-top: 10px; margin-bottom: 10px;">
                            <v-col 
                                cols="4"
                                lg="4"
                                md="4"
                                sm="4"
                            >
                                <label>Notes</label>
                            </v-col>
                            <v-col 
                                style="text-align: right;"
                                cols="8"
                                lg="8"
                                md="8"
                                sm="8"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            style="width: 35px; height: 35px !important;"
                                            class="btnActions v-btn-large"
                                            outlined
                                            fab
                                            v-on="on"
                                            @click="addNewItemNote(item)" 
                                            color="blue"
                                            :key="index"
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add New Note</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <v-simple-table
                                    fixed-header
                                    style="cursor: pointer;"
                                >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Created On
                                                </th>
                                                <th>
                                                    Created By
                                                </th>
                                                <th>
                                                    Note
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                                <th class="text-center">
                                                    &nbsp;
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(itemNote, index) in listNotes.filter(itm => itm.idPermitting == item.id)" :key="index"
                                            >
                                                <td>{{ itemNote.dateRegisterFormatted }}</td>
                                                <td>{{ itemNote.userNameRegister }}</td>
                                                <td>{{ itemNote.notes }}</td>
                                                <td v-if="userLoggedGetters.id == itemNote.idUserRegister">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                class="btnActions v-btn-large"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="editItemNote(itemNote)">mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Edit Item</span>
                                                    </v-tooltip>
                                                </td>
                                                <td v-if="userLoggedGetters.id == itemNote.idUserRegister">
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                class="btnActions v-btn-large"
                                                                style="width: 35px; height:35px !important;"
                                                                icon 
                                                                small 
                                                                outlined
                                                                v-on="on"
                                                            >
                                                                <v-icon small @click="deleteItemNote(itemNote)">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Item</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <v-dialog
            v-model="showDialogItemNote"
            transition="dialog-top-transition"
            persistent
            width="1000"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Add New Note
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-form
                        ref="formCustomItem"
                        v-model="validFormItemNote"
                        lazy-validation
                    >
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Notes</label>
                                
                                <v-textarea
                                    v-model="notes"
                                    single-line
                                    rows="3"
                                    row-height="30"
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 buttonCancelDialog v-btn-large"
                            style=""
                            outlined
                            @click="showDialogItemNote = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            class="mx-2 v-btn-large"
                            outlined
                            @click="saveItemNote()"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <br />
            
        <div 
            class="text-center"
        >            
            <v-row>
                <v-col 
                    style="margin-left: -20px !important;"
                    cols="7"
                    lg="8"
                    md="7"
                    sm="8"
                >
                    <v-row>
                        <v-col 
                            style="text-align: right"
                            cols="6"
                            lg="9"
                            md="7"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        @click="cancel"
                                        style="background-color: var(--color__red) !important; color: #ffffff !important;"
                                    >
                                        Cancel
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            style="text-align: center"
                            cols="6"
                            lg="3"
                            md="5"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        @click="validatePermittingAndSave()"
                                    >
                                        Save
                                    </v-btn>
                                </template>
                                <span>Save and continue later</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <DialogPermitStages 
            v-on:methodConfirmToCall="dialogPermitStages.methodConfirm"
            :showDialogPermitStages.sync="dialogPermitStages.show"
            :idPermitting="dialogPermitStages.idPermitting"
            :itemPermitting="dialogPermitStages.itemPermitting"
            :permitTitle="dialogPermitStages.permitTitle"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import DialogPermitStages from '@/components/Services/DialogPermitStages.vue';
    import JobInformation from "@/components/Services/JobInformation";
	import * as moment from 'moment';
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        PROJECT_STATUS
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation,
            DialogPermitStages
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            listPermittingRequest: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogPermitStages: {
                show: false,
                idPermitting: 0,
                itemPermitting: {},
                permitTitle: "",
                methodConfirm: ( () => {} )
            },

            listNotes: [],
            listFiles: [],
            listPermitStages: [],

            validFormItemNote: true,
            showDialogItemNote: false,
            idPermitting: 0,
            idFileNoc: 0,
            fileNoc: null,
            notes: "",
            itemNotes: null,

            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            PROJECT_STATUS,
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        watch: {
            "listPermittingRequest" () {
            },
        },

        methods: {

            formatExpirationDate() {
                this.listPermittingRequest.forEach(itemPermitting => {                
                    itemPermitting.expirationDateFormatted = this.formatDate(itemPermitting.expirationDate)
                });
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async getLists() {
                this.listPermitStages = await this.$store.dispatch("serviceModule/ListPermitStages");
            },
            
            async getRegister() {
                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }
                }

                const responsePermitting = await this.$store.dispatch("serviceModule/GetPermittingById", this.id);
                
                this.listPermittingRequest = responsePermitting.result.filter(per => per.apply == 1);
                this.listNotes = [];
                this.listFiles = [];

                const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: this.id });

                //DOCUMENTS
                const nocAttachment = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_NOC.value);  

                if (nocAttachment != null && nocAttachment != undefined && nocAttachment.length > 0) {
                    this.idFileNoc = nocAttachment[0].id,
                    this.fileNoc = new File([], nocAttachment[0].fileName)
                }

                this.listPermittingRequest.forEach(itemPermitting => {

                    itemPermitting.expirationDate = itemPermitting.expirationDateCalendar;
                    itemPermitting.notes = null;
                    itemPermitting.idFile = 0;
                    itemPermitting.fileDocument = null;

                    if (itemPermitting.status != null && itemPermitting.status != undefined && itemPermitting.status != 0) {
                        itemPermitting.statusSelected = {
                            id: itemPermitting.status,
                            description: itemPermitting.statusDescription
                        }
                    }

                    this.$store.dispatch("serviceModule/GetPermittingNotesById", itemPermitting.idPermitting).then (response => {

                        response.result.forEach(itemResult => {
                            this.listNotes.push(itemResult);
                        })
                    });

                    
                    this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: itemPermitting.idPermitting }).then (response => {

                        if (response != null && response != undefined) {
                            response.forEach(itemFile => {
                    
                                if (itemFile.typeDocument == TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value) {
                                    itemPermitting.idFile = itemFile.id;
                                    itemPermitting.fileDocument = new File([], itemFile.fileName);
                                }

                                this.$forceUpdate();
                            })
                        }
                    });
                });
            },

            getResponsible(itemPermitting, itemResponsible) {

                let responsible = itemResponsible.responsibleDescription;

                if (itemResponsible.id == 3) {
                    if (itemPermitting.nameContractor != null && itemPermitting.nameContractor != undefined && itemPermitting.nameContractor != '') {
                        responsible += ` - ${itemPermitting.nameContractor}`;
                    }
                }

                return responsible;
            },

            async cancel() {
                this.$router.push({ path: "/permitting/pipeline" });
            },

            async addNewItemNote(item) {
                this.showDialogItemNote = true;
                this.itemNotes = null;
                this.idPermitting = item.id;
                this.notes = "";
            },

            async editItemNote(item) {
                this.showDialogItemNote = true;
                this.itemNotes = item;
                this.notes = item.notes;
            },

            async deleteItemNote(item) {

                let index = 0
                this.listNotes.forEach(itemNote => {
                    if (itemNote.id == item.id) {

                        this.listNotes.splice(index, 1);
                    }
                    index++;
                });
            },

            async saveItemNote() {

                if (this.itemNotes == null) {
                    this.listNotes.push({
                        id: Math.random(),
                        idPermitting: this.idPermitting,
                        dateRegisterFormatted: moment().format("MM/DD/YYYY HH:mm:ss"),
                        idUserRegister: this.userLoggedGetters.id,
                        userNameRegister: this.userLoggedGetters.name,
                        notes: this.notes,
                        newRegister: true
                    })
                }
                else {
                    this.itemNotes.notes = this.notes
                }

                this.showDialogItemNote = false;
            },

            async uploadFile(id, idKey, file, typeDocument, deleted = false) {

                const attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async save(moveToNextStep) {

                this.showLoading();

                //FILES
                this.listPermittingRequest.forEach(itemPermitting => {

                    if (itemPermitting.statusSelected != null && itemPermitting.statusSelected != undefined) {
                        itemPermitting.status = itemPermitting.statusSelected.id
                    }

                    if (itemPermitting.sentCity == null || itemPermitting.sentCity == undefined) {
                        itemPermitting.sentCity = 0;
                    }

                    if (itemPermitting.expirationDate == "") {
                        itemPermitting.expirationDate = null;
                    }

                    if (itemPermitting.permitUploadDocument == 1 && itemPermitting.fileDocument != null && itemPermitting.fileDocument != undefined) {
                        this.uploadFile(itemPermitting.idFile, itemPermitting.idPermitting, itemPermitting.fileDocument, TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value);
                    }
                });

                if (this.fileNoc != null && this.fileNoc != undefined) {
                    this.uploadFile(this.idFileNoc, this.id, this.fileNoc, TYPE_DOCUMENT.PERMITTING_NOC.value);
                }

                const result = await this.$store.dispatch("serviceModule/CreateUpdatePermitting", this.listPermittingRequest);

                if (result.success === true) {

                    let listNewNotes = this.listNotes.filter (not => not.newRegister === true);
                    if (listNewNotes != null && listNewNotes != undefined) {
                        listNewNotes.forEach (itemNote => {
                            itemNote.id = 0;
                        })
                    }

                    this.listNotes.forEach(itemNote => {
                        this.$store.dispatch("serviceModule/CreateUpdatePermittingNotes", itemNote)
                    })

                    this.showToast("success", "Success!", result.message);

                    if (moveToNextStep == true) {

                        let updateStatusRequest = {
                            id: this.id,
                            status: this.PROJECT_STATUS.PERMITTING_FILE_REVIEW
                        };

                        await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);
                    }

                    this.cancel();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }

                this.hideLoading();
            },

            async validatePermittingAndSave() {

                let isValid = true;

                this.listPermittingRequest.forEach(itemPermitting => {

                    if (itemPermitting.statusSelected == null || itemPermitting.statusSelected == undefined || itemPermitting.statusSelected.id != 3) {
                        isValid = false;
                    }

                    if (itemPermitting.permitUploadDocument == null || itemPermitting.permitUploadDocument == undefined || (itemPermitting.permitUploadDocument == 1) && (itemPermitting.fileDocument == null || itemPermitting.fileDocument == undefined)) {
                        isValid = false
                    }

                    if (itemPermitting.sentCity != 1) {
                        isValid = false
                    }
                });

                if (isValid) {
                    
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `You will move this project to the last step "PERMIT FILE REVIEW", confirm your decision?`,
                        methodConfirm: this.save,
                        params: true
                    };
                }
                else {
                    this.save(false);
                }
            },

            async openPermitStages(item) {
                this.dialogPermitStages = {
                    show: true,
                    idPermitting: item.id,
                    itemPermitting: item,
                    permitTitle: item.permitTitle,
                    methodConfirm: ( () => {
                        this.getRegister();
                    } )
                }
            },

            getBackgroundColor(item, itemPermitStage) {
                const itemPermittingStaging = item.listPermittingStaging.filter(item => item.permitStageSelected.id == itemPermitStage.id)[0];

                let color = "var(--color__silver)";

                if (itemPermittingStaging != null && itemPermittingStaging != undefined) {
                    if (itemPermittingStaging.permitStageStatusSelected.id == 1 || 
                        itemPermittingStaging.permitStageStatusSelected.id == 4) { //approved
                        color = "var(--color__main)";
                    }
                    else if (itemPermittingStaging.permitStageStatusSelected.id == 2) { //denied
                        color = "var(--color__red)";
                    }
                    else if (itemPermittingStaging.permitStageStatusSelected.id == 3) { //In Progress
                        color = "var(--color__alert)";
                    }
                }

                return `background-color: ${color};`
            },

            getDescriptionStagging(item, itemPermitStage) {
                const itemPermittingStaging = item.listPermittingStaging.filter(item => item.permitStageSelected.id == itemPermitStage.id)[0];

                const description = itemPermitStage.permitStageDescription;
                let statusDescription = " [Not Started]";
                
                if (itemPermittingStaging != null && itemPermittingStaging != undefined) {
                    statusDescription = ` [${itemPermittingStaging.permitStageStatusSelected.permitStageStatusDescription}]`
                }

                return `${description} ${statusDescription}`;
            }
        },


        async created() {

            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .btnToogleApproved {
        background-color: transparent !important; 
        color: var(--color__main) !important;
    }

    .btnToogleDenied {
        background-color: transparent !important; 
        color: var(--color__red) !important;
    }

    .div-stepper {
        display: flex; 
        padding: 10px; 
        width: 100%;
        cursor: pointer;
    }

    .border-rounded {
        border: none; 
        border-radius: 10px; 
        height: 20px; 
        width: 25px;
    }

    .step-divisor {
        border-bottom: solid 1px gray; 
        width: 30px; 
        height: 10px;
    }

</style>